.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Smart-logo {
  width: 100%;
  max-width: 180px;
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
}
.title {
  color: 'white';
}
.image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

hr.colored {
  height: 8px;
  background: linear-gradient(
    to left,
    rgba(196, 222, 138, 1) 0%,
    rgba(196, 222, 138, 1) 12.5%,
    rgba(245, 253, 212, 1) 12.5%,
    rgba(245, 253, 212, 1) 25%,
    rgba(255, 208, 132, 1) 25%,
    rgba(255, 208, 132, 1) 37.5%,
    rgba(242, 122, 107, 1) 37.5%,
    rgba(242, 122, 107, 1) 50%,
    rgba(223, 157, 185, 1) 50%,
    rgba(223, 157, 185, 1) 62.5%,
    rgba(192, 156, 221, 1) 62.5%,
    rgba(192, 156, 221, 1) 75%,
    rgba(95, 156, 217, 1) 75%,
    rgba(95, 156, 217, 1) 87.5%,
    rgba(94, 190, 227, 1) 87.5%,
    rgba(94, 190, 227, 1) 87.5%,
    rgba(94, 190, 227, 1) 100%
  );
}